<template lang="pug">
div
  .waitingWindow.surface( v-if="isWaitImagePlanePick" )
    .waitingPanelTitle
      v-icon.mt-2 mdi-information-outline
      span.ml-2.mr-2.mt-1 {{ "Расположите изображение кликом по модели" }}
      app-button.buttonMargin( @click="createByDefaultPosition()" :small="true" :action="true" ) {{ "Установить по умолчанию" }}
  .editWindow.surface( v-if="controlImagePlaneEnabled" ) 
    .panelTitle
      v-icon mdi-information-outline
      span.ml-2 {{ "Зафиксируйте требуемое положение" }}
    .edit
      .namings
        span.span {{ "Наименование" }}
        span.span {{ "Масштаб" }}
        span.span {{ "Z-отметка" }}
      .inputs
        input.input( v-model="imageTitle" @click.stop="")
        input.input( v-model="imageSize" @click.stop="" @input="inputImageSize") 
        input.input( v-model="imageCoordZ" @click.stop="" @input="inputImageCoord") 
      .buttons
        app-button.buttonMargin( @click="setDefaultPosition()" :small="true" :action="true" ) {{ "Установить по умолчанию" }}
        app-button.buttonMargin( @click="saveEditImagePlane()" :small="true" :action="true" ) {{ "Сохранить" }}
        app-button.buttonMargin( @click="cancelEditImagePlane()" :small="true" :cancel="true" ) {{ "Отмена" }}
</template>


<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {

  data() {
    return {
      imageSize: 100,
      imageTitle: "Чертеж 1",
      imageCoordZ: 0,
      oldVer: false
    }
  },

  watch: {
    controlImagePlaneEnabled() {
      this.imageSize = XeokitMediator.ImagePlanes.activeEditImagePlane?.size.toFixed(3) || 100
      this.imageCoordZ = (XeokitMediator.ImagePlanes.activeEditImagePlane?.position[2].toFixed(3) || 0)
      this.imageTitle = XeokitMediator.ImagePlanes.activeEditImagePlane?.imagePlaneTitle || "Чертеж"
    },

    controlImagePlaneCoordZ(value) {
      this.imageCoordZ = value
    },

    controlImagePlaneSize(value) {
      this.imageSize = value
    }
  },

  computed: {
    isWaitImagePlanePick() {
      return XeokitMediator.PickImagePlane.isWaitImagePlanePick
    },

    controlImagePlaneEnabled() {
      return XeokitMediator.ImagePlanes.controlImagePlaneEnabled
    },

    controlImagePlaneSize() {
      return XeokitMediator.ImagePlanes.controlImagePlaneSize.toFixed(3)
    },

    controlImagePlaneCoordZ() {
      return XeokitMediator.ImagePlanes.controlImagePlaneCoordZ.toFixed(3)
    },

    coordChangerTemp() {
      return XeokitMediator.ImagePlanes.coordChangerTemp
    }
  },

  methods: {
    cancelPickImagePlane() {
      XeokitMediator.PickImagePlane.cancelWaitImagePlanePick()
    },

    cancelEditImagePlane() {
      XeokitMediator.ImagePlanes.cancelEditImagePlanes()
    },

    saveEditImagePlane() {
      XeokitMediator.ImagePlanes.saveEditImagePlanes({title: this.imageTitle})
    },

    createByDefaultPosition() {
      XeokitMediator.PickImagePlane.cancelWaitImagePlanePick()
      XeokitMediator.ImagePlanes.createByDefaultPosition()
    },

    setDefaultPosition() {
      XeokitMediator.ImagePlanes.setDefaultPosition()
    },

    inputImageSize() {
      try {
        XeokitMediator.ImagePlanes.activeEditImagePlane.size = Number(this.imageSize)
      }
      catch(ex) {
        console.log(ex)
      } 
    },

    inputImageCoord() {
      let coord = XeokitMediator.ImagePlanes.activeEditImagePlane?.position || [0, 0, 0]
      coord[2] = this.imageCoordZ
      try {
        XeokitMediator.ImagePlanes.coordChangerTemp = true
        XeokitMediator.ImagePlanes.activeEditImagePlane.position = coord
      }
      catch(ex) {
        console.log(ex)
      } 
    }
  },
}
</script>

<style lang="scss" scoped>
.alerts {
  background: transparent;
  position: fixed;
  top: 80px;
  right: 10px;
  z-index: 1000;
}

.waitingWindow {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 35vw;
  margin-right: 8px;
  bottom: 7px;
  width: 612px;
  height: 48px;
}

.editWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 35vw;
  margin-right: 8px;
  bottom: 16px;
  width: 508px;
  height: 148px;
}

.edit {
  display: flex;
  flex-direction: row;
}

.namings {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  height: 72px;
}

.waitingPanelTitle {
  margin: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.panelTitle {
  margin: 8px;
  margin-bottom: 20px;
}

.inputs {
  box-sizing: border-box;
  border-color: white;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  height: 72px;
  width: 150px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  height: 72px;
}

.buttonMargin {
  margin: 3px;
}

.input {
  padding-left: 6px;
  margin-bottom: 2px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: white;
  color: white;
}

.span {
  margin-bottom: 4px;
}
</style>